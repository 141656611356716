import { useRef , useState} from 'react';
import Input from '../../UI/Input';
import classes from './MealItemForm.module.css';

const MealItemForm = props =>{
  const amountInputRef = useRef();
   const [amountIsValid, setAmountIsValid] = useState(true);

  const submitHandler = event =>{
    event.preventDefault();

    const enteredAmount = amountInputRef.current.value;   // always return STRING value 
    const enteredAmountNumber = +enteredAmount;
     if(enteredAmount.trim().length === 0 || enteredAmountNumber < 1 || enteredAmountNumber > 10 )
     {
      setAmountIsValid(true);
       return ;
     }  
       
     props.onAddToCart(enteredAmountNumber);

   }
  return (
       <form className={classes.form} onSubmit = {submitHandler}>
           <Input 
           ref ={amountInputRef} 
            label='Amount'
            input= {{
                id: 'amount_' + props.id,
                type : 'number',
                min:'1',
                max:'10',
                step: '1',
                defaultValue: '1'
            }}
           />
           <button>+Add</button>
           {!amountIsValid && <p> Please Enter a Valid Amount (1-10) </p>}
       </form>
  );
}
export default MealItemForm;