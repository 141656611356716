import Card from "../UI/Card";
import classes from "./AvailableMeals.module.css";
import MealItem from "./MealItem/MealItem";

const DUMMY_MEALS = [
  {
    id: "m1",
    name: "Basha Fish",
    description: "Finest fish and veggies added",
    price: 222.99,
  },
  {
    id: "m2",
    name: "Veg Chowmin",
    description: "Noodles cooks in finest oil!",
    price: 160.5,
  },
  {
    id: "m3",
    name: "Barbecue Chicken",
    description: "Indian, raw, with Indian spice ",
    price: 312.99,
  },
  {
    id: "m4",
    name: "Non-Veg Soup Bowl",
    description: "Healthy...and meat.. chicken..",
    price: 180.99,
  },
  {
    id: "m5",
    name: "Veg Soup Bowl",
    description: "Healthy...and Green Veggies..",
    price: 150.99,
  },
  {
    id: "m6",
    name: "Veg Roll",
    description: "Healthy...and Green Veggies..",
    price: 110.50,
  },
  {
    id: "m7",
    name: "Tikka Paneer",
    description: "Healthy...and Green Veggies..",
    price: 350.99,
  },
  {
    id: "m8",
    name: "Aloo Sabji",
    description: "Healthy...and Green Veggies..",
    price: 160.99,
  },
];

const AvailableMeals = (props) => {
  const mealsList = DUMMY_MEALS.map((meal) => (
    <MealItem
    id={meal.id} // this is new! 
    key={meal.id}
    name={meal.name} 
    description={meal.description}
    price={meal.price} 
    />
  ));
  return (
    <section className={classes.meals}>
      <Card>
        <ul>{mealsList}</ul>
      </Card>
    </section>
  );
};
export default AvailableMeals;
